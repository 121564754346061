
































































































































































































































































































import { debounceProcess } from "@/helpers/debounce";
import { useLocalFilter } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DATE_FORMAT_DD_MMM_YYYY,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import { Mode } from "@/models/enums/global.enum";
import { Messages } from "@/models/enums/messages.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { generalJournalServices } from "@/services/generaljournal.service";
import {
  changeCurrencytoNumeric,
  currencyFormat,
} from "@/validator/globalvalidator";
import moment from "moment";
import Vue from "vue";
import { TRANSFER_TO_ASSET } from "./enums";
import { transferToAssetServices } from "./services/transfer-to-asset.service";
import {
  DataResponseListTransferToAsset,
  RequestTransferToAsset,
  ResponseListTransferToAssetForm,
  ResponseListTransferToAssetParam,
} from "./types/transfer-to-asset.type";

export default Vue.extend({
  name: "CreateTransferToAsset",
  mixins: [MNotificationVue],
  data() {
    this.getProductCode = debounceProcess(this.getProductCode, 200);
    this.getProductName = debounceProcess(this.getProductName, 200);
    return {
      DEFAULT_DATE_FORMAT,
      DATE_FORMAT_DD_MMM_YYYY,
      journalNumber: "",
      mode: "" as Mode,
      form: this.$form.createForm(this, { name: "resultworkorder" }),
      formFind: this.$form.createForm(this, { name: "resultworkorderFind" }),
      view: false,
      productCode: "",
      productName: "",
      dataProductCode: [] as ResponseListTransferToAssetParam[],
      dataProductName: [] as ResponseListTransferToAssetParam[],
      dataSerialNumber: [] as string[],
      dataForm: {} as ResponseListTransferToAssetForm,
      formRulesFind: {
        productCode: {
          label: "lbl_part_number",
          name: "productCode",
          placeholder: "lbl_part_number_placeholder",
          decorator: [
            "productCode",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        productName: {
          label: "lbl_part_name",
          name: "productName",
          placeholder: "lbl_part_name_placeholder",
          decorator: [
            "productName",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        serialNumber: {
          label: "lbl_serial_number",
          name: "serialNumber",
          placeholder: "lbl_serial_number_placeholder",
          decorator: [
            "serialNumber",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
      formRules: {
        documentNumber: {
          label: "lbl_document_number",
          name: "documentNumber",
          placeholder: "lbl_auto_generate",
          decorator: [
            "documentNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        journalNumber: {
          label: "lbl_journal_number",
          name: "journalNumber",
          placeholder: "lbl_auto_generate",
          decorator: [
            "journalNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        trackAsAsset: {
          label: "lbl_track_as_asset",
          name: "trackAsAsset",
          placeholder: "lbl_track_as_asset_placeholder",
          decorator: [
            "trackAsAsset",
            {
              valuePropName: "checked",
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        transferDate: {
          label: "lbl_transfer_date",
          name: "transferDate",
          placeholder: "lbl_transfer_date_placeholder",
          decorator: [
            "transferDate",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        serialNumber: {
          label: "lbl_serial_number",
          name: "serialNumber",
          placeholder: "lbl_serial_number_placeholder",
          decorator: [
            "serialNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        location: {
          label: "lbl_location",
          name: "location",
          placeholder: "lbl_auto_generate",
          decorator: [
            "location",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        productCode: {
          label: "lbl_part_number",
          name: "productCode",
          placeholder: "lbl_part_number_placeholder",
          decorator: [
            "productCode",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        productName: {
          label: "lbl_part_name",
          name: "productName",
          placeholder: "lbl_part_name_placeholder",
          decorator: [
            "productName",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        poNumber: {
          label: "lbl_po_number",
          name: "poNumber",
          placeholder: "lbl_po_number_placeholder",
          decorator: [
            "poNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        receiveNumber: {
          label: "lbl_receive_number",
          name: "receiveNumber",
          placeholder: "lbl_receive_number_placeholder",
          decorator: [
            "receiveNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        invoiceNumber: {
          label: "lbl_invoice_number",
          name: "invoiceNumber",
          placeholder: "lbl_invoice_number_placeholder",
          decorator: [
            "invoiceNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        supplierName: {
          label: "lbl_supplier_name",
          name: "supplierName",
          placeholder: "lbl_supplier_name_placeholder",
          decorator: [
            "supplierName",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        qty: {
          label: "lbl_qty",
          name: "qty",
          placeholder: "lbl_qty_placeholder",
          decorator: [
            "qty",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        uom: {
          label: "lbl_uom",
          name: "uom",
          placeholder: "lbl_uom_placeholder",
          decorator: [
            "uom",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        merk: {
          label: "lbl_merk",
          name: "merk",
          placeholder: "lbl_merk_placeholder",
          decorator: [
            "merk",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        cost: {
          label: "lbl_cost",
          name: "cost",
          placeholder: "lbl_cost_placeholder",
          decorator: [
            "cost",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        specification: {
          label: "lbl_specification",
          name: "specification",
          placeholder: "lbl_specification_placeholder",
          decorator: [
            "specification",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        description: {
          label: "lbl_description",
          name: "description",
          placeholder: "lbl_description_placeholder",
          decorator: [
            "description",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
      loading: {
        productCode: false,
        productName: false,
        find: false,
        save: false,
      },
    };
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 5 },
        wrapperCol: { span: 14 },
      };
    },
  },
  created() {
    this.mode = this.$route.meta.mode;
    this.getProductCode();
    this.getProductName();
  },
  mounted() {
    if (this.mode === Mode.VIEW) {
      this.view = true; // disable or not
      const data = localStorage.getItem(TRANSFER_TO_ASSET.FORM_DATA);
      if (!data) return;
      const dataStore: DataResponseListTransferToAsset = JSON.parse(
        data
      ) as DataResponseListTransferToAsset;
      this.getDetail(dataStore);
    }
  },
  destroyed() {
    localStorage.removeItem(TRANSFER_TO_ASSET.FORM_DATA);
  },
  methods: {
    useLocalFilter,
    getDetail(data: DataResponseListTransferToAsset): void {
      this.journalNumber = data.journalNumber;
      this.form.setFieldsValue({
        documentNumber: data.documentNumber,
        trackAsAsset: data.trackAsAsset,
        transferDate: moment(data.transferDate, DATE_FORMAT_DD_MMM_YYYY),
        serialNumber: data.serialNumber,
        location: data.locationDesc,
        productCode: data.productCode,
        productName: data.productName,
        poNumber: data.poNumber,
        receiveNumber: data.receiveNumber,
        invoiceNumber: data.invoiceNumber,
        supplierName: data.supplierName,
        qty: data.quantity,
        uom: data.uom,
        merk: data.merk,
        cost: data.cost,
        specification: data.specification,
        description: data.description,
      });
    },
    async clickJournal() {
      if (!this.journalNumber) return;
      try {
        const param = new RequestQueryParams(
          `name~${this.journalNumber}`,
          0,
          "createdDate:desc"
        );
        const data = await generalJournalServices.listGeneralJournal(param);
        if (!data.data.length) return;
        this.$confirm({
          title: this.$t("lbl_validation_redirect"),
          onOk: () => {
            this.$router.push(
              `/generaljournal/journal/detail/${data.data[0].id}`
            );
          },
          onCancel() {
            return;
          },
        });
      } catch (error) {
        this.showNotifError("notif_process_fail");
      }
    },
    handleChangeSerialNumber(serialNumber: string): void {
      // specification
      let specification = "-";
      this.dataProductCode.forEach(dataForeach => {
        if (dataForeach.productSnSpecificationList) {
          dataForeach.productSnSpecificationList.forEach(dataLoop => {
            if (dataLoop.serialnumber === serialNumber) {
              specification = dataLoop.specification;
            }
          });
        }
      });
      this.form.setFieldsValue({
        specification,
      });
    },
    handleProductName(productName) {
      this.productName = productName;
      this.formFind.resetFields(["serialNumber"]);
      this.dataProductName.forEach(dataForeach => {
        if (dataForeach.productName === productName) {
          this.formFind.setFieldsValue({
            productCode: dataForeach.productCode,
          });
          this.dataProductCode = [
            {
              productCode: dataForeach.productCode,
              productName: dataForeach.productName,
              productId: dataForeach.productId,
              serialNumbers: dataForeach.serialNumbers,
              productSnSpecificationList:
                dataForeach.productSnSpecificationList,
            },
          ];
          let counter = 0;
          this.dataSerialNumber = dataForeach.serialNumbers.filter(() => {
            counter++;
            return counter < 10;
          });
        }
      });
    },
    handleProductCode(productCode: string | null) {
      this.formFind.resetFields(["serialNumber"]);
      if (!productCode) return;
      this.productCode = productCode;
      const [filtered] = this.dataProductCode.filter(
        item => item.productCode === productCode
      );
      this.formFind.setFieldsValue({
        productName: filtered.productName,
      });
      this.dataProductName = [
        {
          productCode: filtered.productCode,
          productName: filtered.productName,
          productId: filtered.productId,
          serialNumbers: filtered.serialNumbers,
          productSnSpecificationList: filtered.productSnSpecificationList,
        },
      ];
      this.dataSerialNumber = [...filtered.serialNumbers];
    },
    getSerialNumber(): void {
      if (!this.productCode) {
        this.showNotifError("notif_select_product_code_to_get_serial_number");
        return;
      }

      const [filtered] = this.dataProductCode.filter(
        item => item.productCode === this.productCode
      );
      this.dataSerialNumber = filtered ? filtered.serialNumbers : [];
    },
    getProductCode(valueSearch = ""): void {
      const params: RequestQueryParamsModel = { list: false };

      if (valueSearch) params.code = `${valueSearch}`;
      if (this.productName) params.name = `${this.productName}`;

      this.loading.productCode = true;
      transferToAssetServices
        .getListTransferToAssetParam(params)
        .then(data => {
          this.dataProductCode = data;
        })
        .finally(() => (this.loading.productCode = false));
    },
    getProductName(valueSearch = ""): void {
      const params: RequestQueryParamsModel = { list: false };

      if (valueSearch) params.name = `${valueSearch}`;
      if (this.productCode) params.code = `${this.productCode}`;
      this.loading.productName = true;
      transferToAssetServices
        .getListTransferToAssetParam(params)
        .then(data => {
          this.dataProductName = data;
        })
        .finally(() => (this.loading.productName = false));
    },
    handleBack() {
      this.$confirm({
        title: this.$t("lbl_leave_page"),
        onOk: () => {
          this.$router.push("/logistic/transfer-to-asset");
        },
        onCancel() {
          return;
        },
      });
    },
    handleSave() {
      this.form.validateFields((err, res) => {
        if (err) return;
        const payload: RequestTransferToAsset = {
          ...this.dataForm,
          cost: changeCurrencytoNumeric(res.cost),
          description: res.description,
          serialNumber: res.serialNumber,
          transferDate: moment(res.transferDate).format("yyyy-MM-DD"),
          specification: res.specification,
        };
        this.loading.save = true;
        transferToAssetServices
          .createTransferToAsset(payload)
          .then(documentNumber => {
            this.showNotifSuccess("notif_create_success", { documentNumber });
            this.form.resetFields();
          })
          .catch(() => this.showNotifSuccess("notif_create_fail"))
          .finally(() => (this.loading.save = false));
      });
    },
    handleFind(): void {
      this.formFind.validateFields((err, res) => {
        if (err) return;
        const params: RequestQueryParamsModel = {};
        if (res.productCode) {
          params.code = `${res.productCode}`;
        }

        if (res.serialNumber) {
          params.sn = `${res.serialNumber}`;
        }

        if (res.productName) {
          params.name = `${res.productName}`;
        }

        this.loading.find = true;
        transferToAssetServices
          .getListTransferToAssetForm(params)
          .then(response => {
            this.dataForm = response;
            this.journalNumber = response.journalNumber;
            this.form.setFieldsValue({
              documentNumber: response.documentNumber,
              trackAsAsset: response.trackAsAsset,
              transferDate: response.transferDate
                ? moment(response.transferDate).format(DATE_FORMAT_DD_MMM_YYYY)
                : moment().format(DATE_FORMAT_DD_MMM_YYYY),
              serialNumber: response.serialNumber
                ? response.serialNumber
                : res.serialNumber,
              location: response.locationDesc,
              productCode: response.productCode,
              productName: response.productName,
              poNumber: response.poNumber,
              receiveNumber: response.receiveNumber,
              invoiceNumber: response.invoiceNumber,
              supplierName: response.supplierName,
              qty: response.quantity,
              uom: response.uom,
              merk: response.merk,
              cost: currencyFormat(response.cost),
            });
          })
          .finally(() => (this.loading.find = false));
      });
    },
  },
});
